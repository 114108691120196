import React, { useState, useEffect } from 'react';
import {
  FaGoogle,
  FaYahoo,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaTiktok,
  FaLinkedin,
  FaSnapchat,
  FaPinterest,
  FaReddit,
  FaWhatsapp,
  FaWeixin,
  FaTelegram,
  FaSkype,
  FaSlack,
  FaYoutube,
  FaVimeo,
  FaTumblr,
  FaDiscord,
  FaSignal,
  FaViber,
  FaVideo,
} from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { SiProtonmail, SiAol } from 'react-icons/si';

const allIcons = [
  { name: 'Gmail', icon: <FaGoogle className="icon" />, url: 'https://mail.google.com' },
  { name: 'Yahoo Mail', icon: <FaYahoo className="icon" />, url: 'https://mail.yahoo.com' },
  { name: 'Outlook', icon: <MdEmail className="icon" />, url: 'https://outlook.live.com' },
  { name: 'AOL Mail', icon: <SiAol className="icon" />, url: 'https://mail.aol.com' },
  { name: 'YouTube', icon: <FaYoutube className="icon" />, url: 'https://youtube.com' },
  { name: 'Facebook', icon: <FaFacebook className="icon" />, url: 'https://facebook.com' },
  { name: 'Twitter', icon: <FaTwitter className="icon" />, url: 'https://twitter.com' },
  { name: 'Instagram', icon: <FaInstagram className="icon" />, url: 'https://instagram.com' },
  { name: 'Zoom', icon: <FaVideo className="icon" />, url: 'https://zoom.us' },
  { name: 'TikTok', icon: <FaTiktok className="icon" />, url: 'https://tiktok.com' },
  { name: 'WhatsApp', icon: <FaWhatsapp className="icon" />, url: 'https://whatsapp.com' },
  { name: 'LinkedIn', icon: <FaLinkedin className="icon" />, url: 'https://linkedin.com' },
  { name: 'Snapchat', icon: <FaSnapchat className="icon" />, url: 'https://snapchat.com' },
  { name: 'ProtonMail', icon: <SiProtonmail className="icon" />, url: 'https://proton.me' },
  { name: 'Pinterest', icon: <FaPinterest className="icon" />, url: 'https://pinterest.com' },
  { name: 'Reddit', icon: <FaReddit className="icon" />, url: 'https://reddit.com' },
  { name: 'WeChat', icon: <FaWeixin className="icon" />, url: 'https://wechat.com' },
  { name: 'Telegram', icon: <FaTelegram className="icon" />, url: 'https://telegram.org' },
  { name: 'Skype', icon: <FaSkype className="icon" />, url: 'https://skype.com' },
  { name: 'Slack', icon: <FaSlack className="icon" />, url: 'https://slack.com' },
  { name: 'Vimeo', icon: <FaVimeo className="icon" />, url: 'https://vimeo.com' },
  { name: 'Tumblr', icon: <FaTumblr className="icon" />, url: 'https://tumblr.com' },
  { name: 'Discord', icon: <FaDiscord className="icon" />, url: 'https://discord.com' },
  { name: 'Signal', icon: <FaSignal className="icon" />, url: 'https://signal.org' },
  { name: 'Viber', icon: <FaViber className="icon" />, url: 'https://viber.com' },
];

const LpShortcuts = ({ buyFreeTrial }) => {
  // Initialize selectedIcons from localStorage or as an empty array
  const [selectedIcons, setSelectedIcons] = useState(() => {
    const savedIcons = localStorage.getItem('selectedIcons');
    return savedIcons ? JSON.parse(savedIcons) : [];
  });

  // Save selectedIcons to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('selectedIcons', JSON.stringify(selectedIcons));
  }, [selectedIcons]);

  // Function to add or remove icons from selectedIcons
  const toggleIcon = (name) => {
    setSelectedIcons((prevIcons) =>
      prevIcons.includes(name)
        ? prevIcons.filter((icon) => icon !== name)
        : [...prevIcons, name]
    );
  };

  // Create lists of selected and unselected icons
  const selectedIconsList = allIcons.filter((icon) => selectedIcons.includes(icon.name));
  const unselectedIconsList = allIcons.filter((icon) => !selectedIcons.includes(icon.name));

  // Function to handle Save Favorites button click
  const handleSaveFavorites = () => {
    buyFreeTrial();
  };

  return (
    <div className="shortcut-links-container">
      <div className="selected-shortcuts-section">
        <h2>{selectedIconsList.length > 0 ? 'Favorite Sites' : 'Add Your Favorite Sites!'}</h2>
        <div className="shortcut-links">
          {selectedIconsList.length > 0 ? (
            selectedIconsList.map((icon) => (
              <div key={icon.name} className="shortcut-item selected">
                <a href={icon.url} target="_blank" rel="noopener noreferrer">
                  {icon.icon}
                </a>
                <span className="shortcut-name">{icon.name}</span>
                <button
                  onClick={() => toggleIcon(icon.name)}
                  className="toggle-shortcut-button"
                >
                  {'-'}
                </button>
              </div>
            ))
          ) : (
            <div className="shortcut-select-sub">
              No icons selected yet. Click '+' to add icons.
            </div>
          )}
        </div>
        {selectedIconsList.length > 0 && (
          <button onClick={handleSaveFavorites} className="save-favorites-button">
            Save Favorites
          </button>
        )}
      </div>
      {selectedIconsList.length > 0 && <div className="separator"></div>}
      <div className="unselected-shortcuts-section">
        <div className="shortcut-links">
          {unselectedIconsList.map((icon) => (
            <div key={icon.name} className="shortcut-item">
              <a href={icon.url} target="_blank" rel="noopener noreferrer">
                {icon.icon}
              </a>
              <span className="shortcut-name">{icon.name}</span>
              <button
                onClick={() => toggleIcon(icon.name)}
                className="toggle-shortcut-button"
              >
                {'+'}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LpShortcuts;
