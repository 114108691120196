import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const RefundPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="common-top-spacing">
                <section className="privacy-policy-outer">
                    <Container>
                        <div className="privacy-policy-inner">
                            <h1>Refund Policy</h1>

                            <h5>Introduction</h5>
                            <p>Thank you for subscribing to Helpr.chat, a service provided by Nomadic Creative LLC ("we," "our," or "us"). We are committed to ensuring your satisfaction with our services. This Refund Policy outlines the conditions under which refunds may be issued.</p>

                            <h5>1. General Refund Policy</h5>
                            <p>All subscription fees are non-refundable except as outlined in this policy or where required by law. If you have any questions or concerns about your subscription, please contact our support team.</p>

                            <h5>2. Eligibility for Refunds</h5>
                            <h6>2.1 Refund Requests</h6>
                            <p>If you are not satisfied with your subscription, you may request a refund within 7 days of the initial payment. To request a refund, please contact our billing team at <a href="mailto:billing@helpr.chat">billing@helpr.chat</a> with your email address, subscription start date, and any relevant information.</p>
                            <h6>2.2 No Refunds After 7 Days</h6>
                            <p>Refund requests received after 7 days from the payment date will not be processed. You will continue to have access to the paid services until the end of the current billing period, but no prorated refunds will be issued for the remaining period.</p>

                            <h5>3. Subscription Cancellations</h5>
                            <h6>3.1 Canceling Your Subscription</h6>
                            <p>You can cancel your subscription at any time through your account settings. Cancellation will take effect at the end of the current billing period, and you will not be charged for the subsequent period. To avoid being charged for the next billing period, cancel at least 24 hours before the renewal date.</p>
                            <h6>3.2 Automatic Renewals</h6>
                            <p>Your subscription will automatically renew at the end of each billing cycle unless you cancel it at least seven (7) days before the renewal date. You can cancel your subscription through your account settings or by contacting our support team.</p>

                            <h5>4. Payment Methods</h5>
                            <p>All payments for your subscription are processed via Paddle.com, our Merchant of Record. You can pay for your subscription via debit or credit card through Paddle's payment system. Payment will be charged to the payment method you provided at the time of purchase. You authorize Paddle to charge the applicable subscription fees to the payment method that you have chosen.</p>

                            <h5>5. Chargebacks/Disputes</h5>
                            <p>If you initiate a chargeback or otherwise reverse any payment of applicable subscription fees in your account, we may disable or terminate your account immediately at our sole discretion for the breach of your payment obligations hereunder. We reserve the right to dispute any chargeback received, including by providing the relevant financial institution with any information proving that the payment in question was authorized by the user responsible for such chargeback. All chargebacks are handled by Paddle.com. Please review their policy carefully at <a href="https://www.paddle.com/legal/checkout-buyer-terms" target="_blank" rel="noreferrer">https://www.paddle.com/legal/checkout-buyer-terms</a> before making a purchase. For partial monthly subscription refund requests, you can contact us at <a href="mailto:support@helpr.chat">support@helpr.chat</a>.</p>

                            <h5>6. Changes to This Refund Policy</h5>
                            <p>We reserve the right to modify, terminate, or otherwise amend our offered subscription plans at any time. We will give you reasonable notice of any such changes by posting them on or through the Service and/or by sending you an email notification, or in other prominent ways. If you do not wish to pay the new fees, you can cancel the applicable subscription prior to the change going into effect.</p>

                            <h5>Contact Us</h5>
                            <p>If you have any questions or suggestions about the Refund Policy, don’t hesitate to contact us via <a href="mailto:support@helpr.chat">support@helpr.chat</a>.</p>
                            <p><em>Last updated: July 29, 2024</em></p>
                        </div>
                    </Container>
                </section>
            </div>
        </>
    );
}
export default RefundPolicy;