import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalContext";
import Header from "./Header";
import { Loader } from "./Loader";
import { SessionLogout } from "./SessionLogout";
import Sidebar from "./Sidebar.js";
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "./Footer.js";

const Main = (props) => {

  const location = useLocation();
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  const [open, setOpen] = useState(true);

  const pathsList = ["/", "/account-setting", "/privacy-policy", "/term-condition", "/refund-policy"]


  useEffect(() => {
    if(globalContext.loggedIn && location.pathname === "/get-help") {
      navigate("/?chatType=text", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      {/* Loader component for loading effect */}
      {globalContext.loader && <Loader />}
      {/* SessionLogout component for 401(Unauthorized users) */}
      {globalContext.sessionComplete && <SessionLogout />}
      {location.pathname.includes("/reset-password/") || location.pathname.includes("/checkout") || location.pathname.includes("/stripe-checkout") || pathsList.indexOf(location.pathname) === -1 ? <>
        {props.children}
      </>
        :
        <>
          {/* App header */}
          <Header open={open} setOpen={setOpen} />
          {/* App sidebar */}
          <Sidebar open={open} setOpen={setOpen} />
          {/* Render the routing components here */}
          <div className={location.pathname==="/" ? "outer-common-div" : ""}>
            {props.children}
            {/* App footer */}
            <Footer />
          </div>
     </>
      }
    </>
  );
};
export default Main;
