// Live
export const socketUrl = "wss://api.helpr.chat/ws/"
export const apiUrl = "https://api.helpr.chat/api/"

// Staging
// export const socketUrl = "wss://staging-api.helpr.chat/ws/"
// export const apiUrl = "https://staging-api.helpr.chat/api/"

// Local
// export const socketUrl = "ws://localhost:8000/ws/"
// export const apiUrl = "http://localhost:8000/api/"

// Ip
// export const socketUrl = "ws://192.168.2.70:8000/ws/"
// export const apiUrl = "http://192.168.2.70:8000/api/"

// Paddle Sandbox
export const paddleClientToken = "test_2d3be3c97af8b25a2f607095bf7"

// Paddle Live
// export const paddleClientToken = "live_b28e3092b166ac4c686519ea03c"

// stripe sandbox
// export const stripePublishKey = "pk_test_51PwHfMJNm3DgKv6YWpFrrVuciKq3WOw0zg6GkyCEzjbkDAmBUE95hEBlFaVf5vsNyUccvJueRcIRfpmSd72IBQo600lPFqoCDx"

// Stripe Live
export const stripePublishKey = "pk_live_51PwHfMJNm3DgKv6Y0SQYrQCtrE11iip5S09ErMxOy2nrOezFYEGYCPEgJTtn07WPb04arwrvnOtJYk1TX8bkVc9F00HymMc8Pe"