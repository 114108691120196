/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { GlobalContext } from "../context/GlobalContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { apiService } from "../service/api.service";
import TagManager from 'react-gtm-module';

export const SetPasswordStripe = () => {
    const navigate = useNavigate();
    const globalContext = useContext(GlobalContext);
    const [queryParams] = useSearchParams();
    const [loader, setLoader] = useState(true);
    const [email, setEmail] = useState("");
    const [transactionDetail, setTransactionDetail] = useState({});
    const [infoMessage, setInfoMessage] = useState("Hold on, we are verifying your details...");
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [passType1, setPassType1] = useState("password");

    // Initialize Tag Manager
    const tagManagerArgs = {
        gtmId: "GTM-MD3PPCXX",
    };
    TagManager.initialize(tagManagerArgs);

    useEffect(() => {
        // Push SetPasswordPageView event to dataLayer when page loads
        window.dataLayer.push({
            event: "SetPasswordPageView",
            title: "SetPassword.js",
        });

        const getStripeTransaction = () => {
            apiService.getStripeCheckout(queryParams.get("session_id")).then((res) => {
                const response = res.data.data;
                console.log(response);

                if (!response.subscription) {
                    // Retry if no subscription data yet
                    setTimeout(() => {
                        getStripeTransaction();
                    }, 1000);
                } else {
                    setTransactionDetail(response);

                    if (!response.customer) {
                        setInfoMessage("Invalid transaction. Please try again.");
                    } else {
                        getStripeCustomerDetail(response.customer, response.subscription);

                        // Check if payment is confirmed as "paid"
                        if (response.payment_status === "paid") {
                            // Push purchase event to dataLayer
                            window.dataLayer.push({
                                event: "purchase",
                                transaction_id: response.id,
                                value: response.amount_total / 100, // Stripe returns amount in cents
                                currency: response.currency,
                            });
                        }
                    }
                }
            })
            .catch((err) => {
                setInfoMessage("Some error occurred. Please try again later.");
                console.log("err: ", err);
            });
        };

        // Call the function once on component mount
        getStripeTransaction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams]);

    const getStripeCustomerDetail = (customer_id, subscription_id) => {
        apiService.getStripeCustomerDetail(customer_id).then((res) => {
            console.log("res: ", res.data);

            if (res.status === 200) {
                if (res.data.userExist) {
                    cancelAddedStripeSubscription(customer_id, subscription_id);
                } else {
                    setEmail(res.data.data.email);
                    setLoader(false);
                }
            }
        })
        .catch((err) => {
            setInfoMessage("Some error occurred. Please try again later.");
            console.log("err: ", err);
        });
    };

    const cancelAddedStripeSubscription = (customer_id, subscription_id) => {
        const payload = { subscription_id: subscription_id };
        apiService.cancelAddedStripeSub(payload)
        .then(() => deleteStripeUser(customer_id))
        .catch(() => deleteStripeUser(customer_id));
    };

    const deleteStripeUser = (customer_id) => {
        apiService.deleteStripeCustomer({ customer_id: customer_id }).then(() => {
            setInfoMessage("Seems like you already have an account. Please login.");
            setTimeout(() => {
                navigate("/?action=login");
            }, 3000);
        }).catch(() => {
            setInfoMessage("Seems like you already have an account. Please login.");
            setTimeout(() => {
                navigate("/?action=login");
            }, 3000);
        });
    };

    const setYourPassword = (data) => {
        globalContext.setLoader(true);
        const payload = {
            email: email,
            password: data.password,
            has_trial: false,
            stripe_customer_id: transactionDetail.customer,
            stripe_subscription_id: transactionDetail.subscription,
        };
        apiService.signup(payload).then((res) => {
            globalContext.setLoader(false);
            if (res.status === 201) {
                localStorage.setItem("user-info", JSON.stringify(res.data));
                globalContext.setLoggedIn(true);
                globalContext.setUserInfo(res.data.data);
                globalContext.setSessionComplete(false);
                navigate("/?chatType=text", { replace: true });
            }
        }).catch((err) => {
            console.log("signup error: ", err);
            globalContext.setLoader(false);
        });
    };

    return (
        <div className="common-top-spacing">
            <section className="account-setting-outer">
                <Container>
                    <Row className="justify-content-center">
                        {!loader ? (
                            <Col md={10} xl={6} xxl={5} className="col-12 mt-5">
                                <div className="login-content-inner">
                                    <div className="login-content-head">
                                        <h2>Set Password</h2>
                                        <p>Set Password for your account, If skipped, you won't be able to recover your account!</p>
                                    </div>
                                    <div className="login-content-body">
                                        <Form onSubmit={handleSubmit(setYourPassword)}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Password</Form.Label>
                                                <div className="password-area">
                                                    <div className="password-area-inner">
                                                        <Form.Control 
                                                            type={passType1} 
                                                            placeholder="Enter Password" 
                                                            {...register("password", { 
                                                                required: "Password is required.", 
                                                                pattern: {
                                                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
                                                                    message: "Password must contain at least 8 characters including uppercase, lowercase, digit, and special character."
                                                                } 
                                                            })} 
                                                            onKeyDown={(e) => { if (e.key === " ") e.preventDefault(); }}
                                                        />
                                                        <i 
                                                            onClick={() => setPassType1(passType1 === "password" ? "text" : "password")} 
                                                            className={`fa-solid fa-eye${passType1 === "password" ? "-slash" : ""}`} 
                                                        />
                                                    </div>
                                                    {errors.password && <span className="err-msg">{errors.password.message}</span>}
                                                </div>
                                            </Form.Group>
                                            <Button variant="primary" type="submit">
                                                Set Password
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        ) : (
                            <Col md={10} xl={6} xxl={5} className="col-12 mt-5">
                                <div className="hold-message">
                                    <img 
                                        src={infoMessage.includes("Hold on") ? require("../assets/images/waiting.gif") : require("../assets/images/error.gif")} 
                                        alt="loader" 
                                    />
                                    <h4>{infoMessage}</h4>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Container>
            </section>
        </div>
    );
};