import axios from "axios";
import { apiUrl } from "../config/config";

export const apiService = {
    login,
    signup,
    logout,
    forgotPassword,
    resetPassword,
    getMyIp,
    getMyProfile,
    getUserThread,
    deleteUserThread,
    getUserThreadMessage,
    getGuestThread,
    getGuestThreadMessage,
    deleteGuestThread,
    updateProfile,
    updatePassword,
    generateImage,
    uploadPdfFiles,
    uploadLink,
    contactUs,

    // Paddle APIs
    getPlans,
    createTransaction,
    createTrialTransaction,
    getTransaction,
    getCustomer,
    getSubscription,
    cancelSubscription,
    cancelAddedSub,

    // Stripe APIs
    getStripePlans,
    createStripeCheckout,
    getStripeCheckout,
    getStripeCustomerDetail,
    getStripeSubscription,
    cancelStripeSubscription,
    cancelAddedStripeSub,
    deleteStripeCustomer,
}

function configHeaders() {
    return {
        headers: {
            'Authorization': `Token ${localStorage.getItem('user-info') ? JSON.parse(localStorage.getItem('user-info')).token : ''}`,
        }
    }
}

function commonHeaders() {
    let headersData = {}
    if (localStorage.getItem('user-info')) {
        headersData = {
            'Authorization': `Token ${JSON.parse(localStorage.getItem('user-info')).token}`,
        }
    }
    return {
        headers: headersData
    }
}

async function login(data) {
    return await axios.post(`${apiUrl}login/`, data);
}

async function signup(data) {
    return await axios.post(`${apiUrl}user/`, data);
}

async function logout(data) {
    return await axios.post(`${apiUrl}logout/`, data, configHeaders());
}

async function forgotPassword(data) {
    return await axios.post(`${apiUrl}reset-password-link/`, data);
}

async function resetPassword(data, token) {
    return await axios.post(`${apiUrl}reset-password/${token}/`, data);
}

async function getMyIp(){
    return await axios.get(`${apiUrl}get-my-ip`)
}

async function getMyProfile(){
    return await axios.get(`${apiUrl}user/my-profile/`, configHeaders())
}

async function getUserThread(){
    return await axios.get(`${apiUrl}user-chat-thread/`, configHeaders())
}

async function deleteUserThread(id){
    return await axios.delete(`${apiUrl}user-chat-thread/${id}/`, configHeaders())
}

async function getUserThreadMessage(id){
    return await axios.get(`${apiUrl}user-chat-thread/${id}/`, configHeaders())
}

async function getGuestThread(){
    return await axios.get(`${apiUrl}guest-chat-thread/`)
}

async function deleteGuestThread(id){
    return await axios.delete(`${apiUrl}guest-chat-thread/${id}/` )
}

async function getGuestThreadMessage(id){
    return await axios.get(`${apiUrl}guest-chat-thread/${id}/` )
}

async function updateProfile(data, id){
    return await axios.put(`${apiUrl}user/${id}/`, data, configHeaders())
}

async function updatePassword(data){
    return await axios.post(`${apiUrl}change-password/`, data, configHeaders())
}

async function generateImage(data){
    return await axios.post(`${apiUrl}generate-image/`, data, configHeaders())
}

async function uploadPdfFiles(data){
    return await axios.post(`${apiUrl}upload-pdf/`, data, configHeaders())
}

async function uploadLink(data){
    return await axios.post(`${apiUrl}upload-link/`, data, configHeaders())
}

async function contactUs(data){
    return await axios.post(`${apiUrl}contact-us/`, data)
}


// PADDLE APIs

async function getPlans(){
    return await axios.get(`${apiUrl}plan-list/`, commonHeaders())
}

async function createTransaction(data){
    return await axios.post(`${apiUrl}transaction/`, data, configHeaders())
}

async function createTrialTransaction(data){
    return await axios.post(`${apiUrl}trial-transaction/`, data)
}

async function getTransaction(id){
    return await axios.get(`${apiUrl}get-transaction/?transaction=${id}`, commonHeaders())
}

async function getCustomer(id){
    return await axios.get(`${apiUrl}get-customer/?customer_id=${id}`)
}

async function getSubscription(){
    return await axios.get(`${apiUrl}get-subscription/`, configHeaders())
}

async function cancelSubscription(){
    return await axios.post(`${apiUrl}cancel-subscription/`, {}, configHeaders())
}

async function cancelAddedSub(data) {
    return await axios.post(`${apiUrl}cancel-added-subscription/`, data)
}


// STRIPE APIs

async function getStripePlans(){
    return await axios.get(`${apiUrl}stripe-plan-list/`, commonHeaders())
}

async function createStripeCheckout(params) {
    return await axios.post(`${apiUrl}create-stripe-checkout/`, params, commonHeaders())
}

async function getStripeCheckout(id) {
    return await axios.get(`${apiUrl}get-stripe-checkout/?transaction=${id}`, commonHeaders())
}

async function getStripeCustomerDetail(id) {
    return await axios.get(`${apiUrl}get-stripe-customer/?customer_id=${id}`, commonHeaders())
}

async function getStripeSubscription() {
    return await axios.get(`${apiUrl}get-stripe-subscription/`, commonHeaders())
}

async function cancelStripeSubscription() {
    return await axios.post(`${apiUrl}cancel-stripe-subscription/`, {}, commonHeaders())
}

async function cancelAddedStripeSub(params) {
    return await axios.post(`${apiUrl}cancel-added-stripe-subscription/`, params, commonHeaders())
}

async function deleteStripeCustomer(params) {
    return await axios.post(`${apiUrl}delete-stripe-customer/`, params, commonHeaders())
}