import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
 
const TermsConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="common-top-spacing">
                <section className="privacy-policy-outer">
                    <Container>
                        <div className="privacy-policy-inner">
                            <h1>Terms & Conditions</h1>

                            <h5>Introduction</h5>
                            <p>Welcome to Helpr.chat, a service provided by Nomadic Creative LLC ("we," "our," or "us"). By accessing or using Helpr.chat ("Service"), you agree to comply with and be bound by the following Terms of Service ("Terms"). Please read them carefully.</p>
                            <p>Helpr.chat is a premium level Large Language model syndicator, AI image generator, productivity dashboard shortcut and organization utility tool, and a web search results platform, all combined into one easy to use interface. Our included consolidated services include but are not limited to GPT-4o by Open AI, and search results provided by Google and Microsoft/Bing.</p>
                            <p>Helpr.Chat is not affiliated with Google, Microsoft, Bing, Open AI, or any other company mentioned or used throughout the application. All usage of said companies products or services through this application are provided through service agreements and official API’s provided by said companies. Productivity and organizational tools are custom designed and implemented by Nomadic Creative for Helpr.Chat.</p>

                            <h5>1. Eligibility</h5>
                            <p>To use our Service, you must be at least 18 years old and capable of forming a binding contract with us. By using our Service, you represent and warrant that you meet these requirements.</p>

                            <h5>2. Account Registration</h5>
                            <p>When you create an account, you must provide accurate and complete information. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your device. You agree to accept responsibility for all activities that occur under your account.</p>

                            <h5>3. Subscription and Payment</h5>
                            <h6>3.1 Fees and Payment</h6>
                            <p>By subscribing to our Service, you agree to pay the subscription fees indicated at the time of purchase. You authorize us to charge your payment method for the subscription fee on a recurring basis until you cancel your subscription. All fees are non-refundable except as required by law or as specified in these Terms.</p>
                            <h6>3.2 Free Trials and Promotions</h6>
                            <p>We may offer free trials or promotional offers at our discretion. If you participate in a free trial, you must cancel before the end of the trial period to avoid being charged for a subscription.</p>
                            <h6>3.3 Subscription Renewals</h6>
                            <p>Your subscription will automatically renew at the end of each billing cycle unless you cancel it at least seven (7) days before the renewal date. You can cancel your subscription through your account settings or by contacting our support team.</p>

                            <h5>4. Usage Restrictions</h5>
                            <p>You agree not to use the Service to:</p>
                            <ul>
                                <li>Upload or distribute any content that infringes on any intellectual property or other proprietary rights of any party.</li>
                                <li>Transmit any material that is unlawful, offensive, or otherwise objectionable.</li>
                                <li>Interfere with or disrupt the Service or servers connected to the Service.</li>
                                <li>Engage in any activity that could harm or damage our reputation or that of our users.</li>
                            </ul>

                            <h5>5. Termination and Suspension</h5>
                            <p>We reserve the right to suspend or terminate your access to the Service if you violate these Terms or if your use of the Service poses a risk to us or others. You may also terminate your account at any time by contacting us. Upon termination, your right to use the Service will immediately cease.</p>

                            <h5>6. Refund Policy</h5>
                            <p>
                            Please see our full refund policy at{" "}
                            <a 
                                href="/refund-policy"
                                style={{
                                    color: '#529fca',
                                    textDecoration: 'underline',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    gap: '4px'
                                }}
                            >
                                Refund Policy
                       
                            </a>
                        </p>

                            <h5>7. Data Privacy and Security</h5>
                            <p>We take your privacy seriously. Please review our Privacy Policy to understand how we collect, use, and protect your personal information. We implement appropriate security measures to safeguard your data but cannot guarantee absolute security.</p>

                            <h5>8. Limitation of Liability</h5>
                            <p>To the maximum extent permitted by law, we are not liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your access to or use of the Service.</p>

                            <h5>9. Dispute Resolution</h5>
                            <p>Any disputes arising out of or relating to these Terms or the Service will be resolved through binding arbitration in accordance with the rules of the American Arbitration Association. You waive your right to participate in class action lawsuits or class-wide arbitration.</p>

                            <h5>10. Changes to These Terms</h5>
                            <p>We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our website. Your continued use of the Service after any changes constitute your acceptance of the new Terms.</p>

                            <h5>Contact Us</h5>
                            <p>If you have any questions about these Terms, please contact us:</p>
                            <ul>
                                <li><b>Email:</b>support@helpr.chat</li>
                                <li><b>Address:</b> Nomadic Creative LLC, 6318 Station Mill Drive, Norcross, GA 30092</li>
                            </ul>
                            <p><em>Last updated: July 29, 2024</em></p>
                        </div>
                    </Container>
                </section>
            </div>
        </>
    );
}
export default TermsConditions;