/* eslint-disable no-unused-vars */

// http://localhost:3000/get-help?campaignid=21847942255&adgroupid={adgroupid}&keyword=email&device={device}&gclid={gclid}

import React, {
    useContext,
    useEffect,
    useRef,
    useState,
    useCallback,
  } from "react";
  import { Button, Col, Row } from "react-bootstrap";
  import { GlobalContext } from "../context/GlobalContext";
  import { useNavigate, useSearchParams } from "react-router-dom";
  import { useForm } from "react-hook-form";
  import { apiService } from "../service/api.service";
  import Swal from "sweetalert2";
  import { Footer } from "../common/Footer";
  import TagManager from "react-gtm-module"; // Import the TagManager
  
  export const LandingPage = () => {
    // Choose which URL parameter to use for dynamic content
    // Uncomment one of the following lines:
    // const paramKey = "keyword"; // Use 'keyword' parameter
    const paramKey = "campaignid"; // Use 'campaignid' parameter
  
    const [headline, setHeadline] = useState(
      "Need Answers? Enter your question to get instant help from your AI expert"
    );
  
    const [messages, setMessages] = useState([]);
    const [conversationFlow, setConversationFlow] = useState([]);
    const [conversationIndex, setConversationIndex] = useState(0);
    const [redirectTimeoutId, setRedirectTimeoutId] = useState(null);
  
    // Set headline based on URL parameter
    useEffect(() => {
      const possibleHeadlines = {
        "21847942255": "Email Problems? Get Instant Help Now", // Email Related (BROAD KWI)
        "21792664256": "Test Headline AI/Chat Specific Terms (EXACT KWI)", // AI/Chat Specific Terms (EXACT KWI)
        "21796679237": "Test Headline AI/Chat Specific Terms (PHRASE KWI)", // AI/Chat Specific Terms (PHRASE KWI)
        "21800609020": "Test Headline AI/Chat Specific Terms (BROAD/PRASE)", // AI/Chat Specific Terms (BROAD/PRASE)
        "21798858401": "Test Headline General Questions (BROAD KWI)", // General Questions (BROAD KWI)
      };
  
      const urlParams = new URLSearchParams(window.location.search);
      const paramValue = urlParams.get(paramKey);
      if (paramValue) {
        const sanitizedParam = paramValue.replace(/[^a-z0-9]/gi, "").toLowerCase();
        let headlineSet = false;
  
        if (paramKey.toLowerCase() === "adgroupid") {
          // Directly compare the sanitized adGroupId
          const key = sanitizedParam;
          if (possibleHeadlines.hasOwnProperty(key)) {
            setHeadline(possibleHeadlines[key]);
            headlineSet = true;
          }
        } else {
          const keywordWords = sanitizedParam.split(" ");
          Object.entries(possibleHeadlines).forEach(([key, value]) => {
            if (!headlineSet) {
              keywordWords.forEach((word) => {
                if (key.toLowerCase() === word.toLowerCase()) {
                  setHeadline(value);
                  headlineSet = true;
                }
              });
            }
          });
        }
      }
    }, []);
  
    // Set initial messages based on URL parameter
    useEffect(() => {
      const possibleConversationFlows = {
        // ---------------------------------------   CAMPAIGN ID CHAT FLOWS   ---------------------------------------
        //  Conversation flow for Email Related (BROAD KWI) ------------------------------------------------
        "21847942255": [
          {
            assistant:
              "<p class='lp-conversation-inner'>Hi I'm Lauren, can you tell me what's going on with your email?</p>",
          },
          {
            assistant:
              "<p class='lp-conversation-inner'>Could you please describe any troubleshooting steps you've already tried?</p>",
          },
          {
            assistant:
              "<p class='lp-conversation-inner'>Okay, rest assured we can get this fixed. I have set up your free unlimited chat and AI assistance to get this solved. To gain access, please finish setting up your free trial on the next page!</p>",
            isFinal: true,
            showSubscribeButton: true,
          },
        ],
        //  Conversation flow for AI/Chat Specific Terms (EXACT KWI) ------------------------------------------------
        "21792664256": [
          {
            assistant:
              "<p class='lp-conversation-inner'>Welcome! I'm Lauren, your personal chat assistant. How can I help you today?</p>",
          },
          {
            assistant:
              "<p class='lp-conversation-inner'>Okay, first let's get you setup with your free unlimited chat and AI assistance to answer any question and help with any request! To gain access, please finish setting up your free trial on the next page</p>",
            isFinal: true,
            showSubscribeButton: true,
          },
        ],
        // ---------------------------------------   KEYWORD BASED CHAT FLOWS   ---------------------------------------
        email: [
          {
            assistant:
              "<p class='lp-conversation-inner'>Hi! Need help with your email? I'm here to assist you with any email-related issues.</p>",
          },
          {
            assistant:
              "<p class='lp-conversation-inner'>Could you please describe the problem you're experiencing with your email?</p>",
          },
          {
            assistant:
              "<p class='lp-conversation-inner'>I can help you get setup with your free unlimited chat and AI assistance to get this solved. To gain access, please finish setting up your free trial on the next page!</p>",
            isFinal: true,
            showSubscribeButton: true,
          },
        ],
        computer: [
          {
            assistant:
              "<p class='lp-conversation-inner'>Welcome! I'm Lauren, your personal chat assistant. How can I help you today?</p>",
          },
          {
            assistant:
              "<p class='lp-conversation-inner'>Okay, first let's get you setup with your free unlimited chat and AI assistance to answer any question and help with any request! To gain access, please finish setting up your free trial on the next page</p>",
            isFinal: true,
            showSubscribeButton: true,
          },
        ],
        // Add more conversation flows as needed
      };
  
      const defaultConversationFlow = [
        {
          assistant:
            "<p class='lp-conversation-inner'>Welcome! I'm Lauren, your personal chat assistant. How can I help you today?</p>",
        },
        {
          assistant:
            "<p class='lp-conversation-inner'>Okay, first let's get you setup with your free unlimited chat and AI assistance to answer any question and help with any request! To gain access, please finish setting up your free trial on the next page</p>",
          isFinal: true,
          showSubscribeButton: true,
        },
      ];
  
      const urlParams = new URLSearchParams(window.location.search);
      const paramValue = urlParams.get(paramKey);
  
      let selectedFlow = defaultConversationFlow;
  
      if (paramValue) {
        const sanitizedParam = paramValue.replace(/[^a-z0-9]/gi, "").toLowerCase();
  
        if (paramKey.toLowerCase() === "adgroupid") {
          const key = sanitizedParam;
          if (possibleConversationFlows.hasOwnProperty(key)) {
            selectedFlow = possibleConversationFlows[key];
          }
        } else {
          const keywordWords = sanitizedParam.split(" ");
          let flowSet = false;
  
          Object.entries(possibleConversationFlows).forEach(([key, flow]) => {
            if (!flowSet) {
              keywordWords.forEach((word) => {
                if (key.toLowerCase() === word.toLowerCase()) {
                  selectedFlow = flow;
                  flowSet = true;
                }
              });
            }
          });
        }
      }
  
      setConversationFlow(selectedFlow);
      setMessages([selectedFlow[0]]); // Start with the first message
    }, []);
  
    const tagManagerArgs = {
      gtmId: "GTM-MD3PPCXX",
    };
  
    TagManager.initialize(tagManagerArgs);
  
    useEffect(() => {
      window.dataLayer.push({
        event: "LpGetHelpPageView",
        title: "LandingPage.js",
      });
    }, []);
  
    const [hasSubmitted, setHasSubmitted] = useState(false); // Track if form has been submitted
  
    const navigate = useNavigate();
    const messagesEndRef = useRef(null);
    const globalContext = useContext(GlobalContext);
  
    const [queryParams] = useSearchParams();
    const [query, setQuery] = useState("");
    const [showLogin, setShowLogin] = useState(false);
    const [showForgot, setShowForgot] = useState(false);
    const [passType, setPassType] = useState("password");
    const [goToCheckout, setGoToCheckout] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const textareaRef = useRef(null); // Create a ref for the textarea
  
    const TypingIndicator = () => {
      return (
        <div className="typing-indicator">
          <span className="typing-responding-formatting">Lauren is responding</span>
          <span className="dots">...</span>
        </div>
      );
    };
    const {
      register,
      handleSubmit,
      formState: { errors },
      setError,
    } = useForm();
  
    const buyFreeTrial = useCallback(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const campaignid = urlParams.get("campaignid"); // Retrieve campaignId from URL
  
      // Pass campaignId and headline to checkout page
      navigate(
        `/stripe-checkout?plan=${selectedPlan}&campaignid=${campaignid}&headline=${encodeURIComponent(
          headline
        )}`,
        { replace: true }
      );
    }, [navigate, selectedPlan, headline]);
  
    const callBuyFreeTrial = () => {
      buyFreeTrial();
    };
  
    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.focus(); // Focus on the textarea when the component loads
      }
    }, []);
  
    useEffect(() => {
      if (queryParams.get("action") === "login") {
        setShowLogin(true);
      }
    }, [queryParams]);
  
    useEffect(() => {
      if (messages.length > 0) {
        // Ensure scrolling after the DOM has updated
        setTimeout(() => {
          if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }, 100); // Add a small timeout to let the DOM update
      }
    }, [messages, goToCheckout, buyFreeTrial]);
  
    useEffect(() => {
      getStripePlanList();
    }, []);
  
    const getStripePlanList = () => {
      apiService
        .getStripePlans()
        .then((res) => {
          if (res.status === 200) {
            setSelectedPlan(res.data.data[0].id);
          }
        })
        .catch((err) => {
          console.log("plan list error: ", err);
        });
    };
  
    const sendMessage = () => {
      // Prevent further input if the final message is displayed
      const finalMessageShown = messages.some((msg) => msg.isFinal);
      if (finalMessageShown) {
        return; // Prevent submission if final message is shown
      }
  
      if (query) {
        // Fire the Google Analytics event on first submit
        if (!hasSubmitted) {
          window.dataLayer.push({
            event: "LpGetHelpChatEnter",
            title: "LandingPage.js",
            eventCategory: "Form",
            eventAction: "Submit",
          });
          setHasSubmitted(true);
        }
  
        const newMessages = [...messages, { user: query }];
        setQuery(""); // Clear input after submitting
        setMessages([...newMessages, { typing: true }]);
  
        setTimeout(() => {
          const updatedMessages = newMessages.filter((msg) => !msg.typing);
          let assistantMessage = null;
          let isFinal = false;
          let showSubscribeButton = false;
  
          const nextIndex = conversationIndex + 1;
  
          if (nextIndex < conversationFlow.length) {
            assistantMessage = conversationFlow[nextIndex].assistant;
            isFinal = conversationFlow[nextIndex].isFinal || false;
            showSubscribeButton = conversationFlow[nextIndex].showSubscribeButton || false;
            setConversationIndex(nextIndex);
          } else {
            // This should not happen, but just in case
            assistantMessage =
              "<p class='lp-conversation-inner'>Thank you! Please proceed to the next page.</p>";
            isFinal = true;
            showSubscribeButton = true;
          }
  
          const newAssistantMessage = {
            assistant: assistantMessage,
            isFinal: isFinal,
            showSubscribeButton: showSubscribeButton,
          };
  
          setMessages([...updatedMessages, newAssistantMessage]);
  
          localStorage.setItem(
            "chatbot-chat",
            JSON.stringify([...updatedMessages, newAssistantMessage])
          );
          textareaRef.current.focus();
  
          if (isFinal) {
            // Start the timeout to redirect after displaying the final message
            const timeoutId = setTimeout(() => {
              callBuyFreeTrial();
            }, 15000); // Delay in milliseconds (15 seconds)
            setRedirectTimeoutId(timeoutId);
          }
        }, 3000); // 3-second delay for typing simulation
      }
    };
  
    // Clear the redirect timeout if the component unmounts to prevent memory leaks
    useEffect(() => {
      return () => {
        if (redirectTimeoutId) {
          clearTimeout(redirectTimeoutId);
        }
      };
    }, [redirectTimeoutId]);
  
    const onSubmitLogin = (data) => {
      if (!hasSubmitted) {
        // First form submission event
        window.dataLayer.push({
          event: "LpGetHelpChatEnter",
          title: "LandingPage.js",
          eventCategory: "Form",
          eventAction: "Submit",
        });
        setHasSubmitted(true);
      }
  
      globalContext.setLoader(true);
      apiService
        .login(data)
        .then((res) => {
          globalContext.setLoader(false);
          if (res.status === 200) {
            localStorage.setItem("user-info", JSON.stringify(res.data));
            globalContext.setLoggedIn(true);
            globalContext.setUserInfo(res.data.data);
            globalContext.setSessionComplete(false);
            setShowLogin(false);
            navigate("/?chatType=text", { replace: true });
          }
        })
        .catch((err) => {
          console.log("login error: ", err);
          globalContext.setLoader(false);
          setError("password", { type: "custom", message: err.response.data.detail });
        });
    };
  
    return (
      <>
        <div className="main-landing-page">
          <header>
            <div className="header-logo">
              <img
                src={require("../assets/images/logo-main.svg").default}
                alt="logo_img"
              />
            </div>
          </header>
  
          <section className="hero-section">
            <div className="hero-text-and-image-container">
              <Row className="align-items-center">
                <Col md={12}>
                  <div className="hero-content">
                    <h1>{headline}</h1>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={12}>
                  <div className="hero-img">
                    <img
                      src={require("../assets/images/arrowimage.png")}
                      alt="logo_img"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </section>
  
          <div className="chat-container">
            <div className="th-prominent-chat dqt-chat">
              <div className="th-prominent-chat-window">
                <div className="chat-header-main">
                  <div className="chat-header-container">
                    <div className="avatar">
                      <div className="avatar-wrapper">
                        <img
                          src={require("../assets/images/helprpfp.png")}
                          alt="logo_img"
                        />
                      </div>
                    </div>
                    <div className="chat-header-details">
                      <p className="chat-header-name">Lauren </p>
                      <p className="chat-header-title">Personal Chat Assistant</p>
                    </div>
                  </div>
                </div>
                <div data-testid="dialog" className="dialog body">
                  <div className="content">
                    <div className="hero-content-mobile-chat-container">
                      <div className="hero-content-mobile-chat-headline">
                        <h1>{headline}</h1>
                      </div>
                    </div>
  
                    <div className="lp-conversation">
                      {messages?.map((msg, index) => (
                        <div key={index}>
                          {msg.typing ? (
                            <TypingIndicator />
                          ) : (
                            <>
                              {msg.assistant && (
                                <div className="lp-conversation-parent-container">
                                  <img
                                    className="right-message-img"
                                    src={require("../assets/images/helprpfp.png")}
                                    alt="logo_img"
                                  />
                                  <div className="content">
                                    <div className="lp-conversation-name">
                                      Lauren, Personal Chat Assistant
                                    </div>
                                    <div className="lp-conversation-text">
                                      <span
                                        dangerouslySetInnerHTML={{ __html: msg?.assistant }}
                                      />
                                      {msg.showSubscribeButton && (
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            buyFreeTrial();
                                          }}
                                          data-testid="subscribe-link-button"
                                          className="subscribe-button-link"
                                        >
                                          <strong>Gain free access! &gt;&gt;</strong>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {msg?.user && (
                                <div className="customer message th-chat-message">
                                  <div className="content">
                                    <div className="text message-text-lp">
                                      <span
                                        dangerouslySetInnerHTML={{ __html: msg?.user }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      ))}
                      <div ref={messagesEndRef} />
                    </div>
                  </div>
                </div>
                <div className="th-chat-integrated-question-box">
                  <div className="wrapper">
                    <div className="text-box text-box-lp-format">
                      <textarea
                        ref={textareaRef}
                        placeholder="Type your question here..."
                        value={query}
                        onChange={(e) => setQuery(e.target.value.trimStart())}
                        data-testid="chat-input"
                        className="text-area"
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            sendMessage();
                          }
                        }}
                      />
                    </div>
                    <div className="bottom">
                      <input
                        type="button"
                        value="Send"
                        onClick={sendMessage}
                        disabled={!query}
                        className="lp-send-btn submit-button dqt-send disabled"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          {/* How It Works Section */}
          <section className="how-it-work">
            <div className="how-it-works-inner">
              <h2>How It Works </h2>
              <div className="how-it-works-inner-content">
                <div className="how-it-works-bullet-numbers">1</div>
                <div className="work-content-right">
                  <h6>Chat</h6>
                  <p>
                    Tell your Helpr Personal AI Assistant what you need help with
                  </p>
                </div>
              </div>
              <div className="how-it-works-inner-content">
                <div className="how-it-works-bullet-numbers">2</div>
                <div className="work-content-right">
                  <h6>Create Account</h6>
                  <p>
                    Create your Helpr account with a free 7 day trial, completely
                    risk free
                  </p>
                </div>
              </div>
              <div className="how-it-works-inner-content">
                <div className="how-it-works-bullet-numbers">3</div>
                <div className="work-content-right">
                  <h6>Solve Your Problem</h6>
                  <p>
                    With the help of your Personal AI Assistant, you can get
                    almost any question answered instantly. 24/7 help and unlimited
                    questions, so you never have to go it alone online
                  </p>
                </div>
              </div>
            </div>
          </section>
  
          {/* Pricing Section */}
          <section className="pricing-area-outer">
            <div className="pricing-area-inner">
              <h2>
                Free 7 Day Trial, Then Only $20/month. No commitments.
              </h2>
              <p>
                Unlock unlimited Helpr chat with your Personal AI Assistant for
                just $20 per month, cancel anytime.
              </p>
              <Button variant="unset" onClick={() => buyFreeTrial()}>
                Get Started
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.2203 10.9062L11.3313 1.14895L1.57769 1.43685M10.2334 2.26696L0.821276 11.8513"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
            </div>
          </section>
        </div>
        <Footer />
      </>
    );
  };
  