/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { stripePublishKey } from "../config/config";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import { apiService } from "../service/api.service";
import { Col, Row } from "react-bootstrap";
import TagManager from "react-gtm-module"; // Import the TagManager


export const StripeCheckout = () => {

  const stripePromise = loadStripe(stripePublishKey);

  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const globalContext = useContext(GlobalContext);
  const [clientSecretKey, setClientSecretKey] = useState("");

  const options = {
    clientSecret: clientSecretKey,
  };

  useEffect(() => {
    if (queryParams.get("plan")) {
      createCheckout();
    }
    // Set clientSecret directly from query params if available
    if (queryParams.get("client_secret")) {
      setClientSecretKey(queryParams.get("client_secret"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [queryParams]);


  const createCheckout = () => {
    globalContext.setLoader(true);
    apiService
      .createStripeCheckout({ plan_id: queryParams.get("plan") })
      .then((res) => {
        globalContext.setLoader(false);
        if (res.status === 200) {
          const secret = res.data.data.client_secret;
          setClientSecretKey(secret); // Set clientSecret first
          // Navigate only if the current clientSecret is not set
          if (!queryParams.get("client_secret")) {
            navigate(`/checkout-short?client_secret=${secret}`, {
              replace: true,
            });
          }
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        globalContext.setLoader(false);
      });
  };



  const tagManagerArgs = {
    gtmId: "GTM-MD3PPCXX",
  };

  TagManager.initialize(tagManagerArgs);

  useEffect(() => {
    window.dataLayer.push({
      event: "CheckoutPageView",
      title: "Checkout.js",
    });
  }, []);




  return (
    <>
{/* 
    <div className="checkout-top-container">
    <div className="checkout-bullet-points-headline">
        <b>Get Unlimited Help From Your
        On Demand AI Personal Assistant!</b>
      </div>
      <div className="checkout-pricing-headline">
        <b>
        Unlimited questions, 7 days free, then just $20/month. Cancel anytime.
        </b>
      </div>
      </div> */}
   

      <Row>
        <div className="shortcuts-checkout-flex-parent">
         
          <div className="shortcuts-chat-container-checkout">
            <div className="th-prominent-chat dqt-chat">
              <div className="th-prominent-chat-window">
              <div className="shortcuts-chat-header-main">
                                <div className="shortcuts-chat-header-container">
                                    <div className="avatar">
                                        <div className="avatar-wrapper">
                                            <img src={require("../assets/images/helprpfp.png")} alt="logo_img" />
                                        </div>
                                    </div>
                                    <div className="chat-header-details">
                                        <p className="chat-header-name">Lauren </p>
                                        <p className="chat-header-title">Personal AI Assistant</p>
                                    </div>
                                </div>
                            </div>
                <div data-testid="dialog-checkout" className="dialog-checkout body">
                  <div className="content">
                    <div className="conversation">
                      <div className="expert message th-chat-message">
                        <img
                          className="right-message-img"
                          src={require("../assets/images/helprpfp.png")}
                          alt="img"
                        />
                        <div className="content">
                          <span className="name">Lauren, Personal AI assistant</span>
                          <div className="checkout-chat-message">
                          Your personal AI chat assistant is ready to help,
                            just fill out the form to claim your 7 day free
                            trial! Get unlimited 24/7 help from your on demand
                            personal AI chat assistant.
                          </div>

                          <div className="checkout-chat-message-900">
                          Your personal AI chat assistant is ready to help,
                            just fill out the form to claim your 7 day free
                            trial! Get unlimited 24/7 help from your on demand
                            personal AI chat assistant.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="checkout-bullet-points-container">
                    {/* <div className="checkout-pricing-headline-900">
                      <b>
                        Unlimited questions, completely free for 7 days, then just
                        $20/month. Cancel anytime.
                      </b>
                    </div> */}
                    <div className="checkout-bullet-points">
                    <div className="checkout-bullet-point">
                                        
                                        <p>Never deal with technology alone again! Your 
                                        AI tech expert is always online and ready to assist!</p>
                                    </div>
                                    <div className="checkout-bullet-point">
                                        
                                        <p>Lifetime free access to your favorite shortcuts home page</p>
                                    </div>
                                    <div className="checkout-bullet-point">
                                       
                                        <p>Ask unlimited questions about any topic 24/7, 
  get an instant response from your expert!
</p>
                                    </div>
                                    <div className="checkout-bullet-point">
                                        
                                        <p>Instant help with any topic, from internet issues, 
  to recipes, math problems, balancing budgets, 
  anything you can think of!</p>
                                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
 
          <div id="checkout" className="shortcuts-stripe-container">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
        


        </div>
      
      </Row>
    </>
  );
};
