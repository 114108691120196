import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaGoogle, FaYahoo, FaFacebook, FaTwitter, FaInstagram, FaTiktok, FaLinkedin, FaSnapchat, FaPinterest, FaReddit, FaWhatsapp, FaWeixin, FaTelegram, FaSkype, FaSlack, FaYoutube, FaVimeo, FaTumblr, FaDiscord, FaSignal, FaViber, FaPencilAlt, FaVideo } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { SiProtonmail, SiAol } from 'react-icons/si';

const allIcons = [
    { name: 'Gmail', icon: <FaGoogle className="icon" />, url: 'https://mail.google.com' },
    { name: 'Yahoo Mail', icon: <FaYahoo className="icon" />, url: 'https://mail.yahoo.com' },
    { name: 'Outlook', icon: <MdEmail className="icon" />, url: 'https://outlook.live.com' },
    { name: 'AOL Mail', icon: <SiAol className="icon" />, url: 'https://mail.aol.com' },
    { name: 'ProtonMail', icon: <SiProtonmail className="icon" />, url: 'https://proton.me' },
    { name: 'Facebook', icon: <FaFacebook className="icon" />, url: 'https://facebook.com' },
    { name: 'Twitter', icon: <FaTwitter className="icon" />, url: 'https://twitter.com' },
    { name: 'Instagram', icon: <FaInstagram className="icon" />, url: 'https://instagram.com' },
    { name: 'TikTok', icon: <FaTiktok className="icon" />, url: 'https://tiktok.com' },
    { name: 'LinkedIn', icon: <FaLinkedin className="icon" />, url: 'https://linkedin.com' },
    { name: 'Snapchat', icon: <FaSnapchat className="icon" />, url: 'https://snapchat.com' },
    { name: 'Pinterest', icon: <FaPinterest className="icon" />, url: 'https://pinterest.com' },
    { name: 'Reddit', icon: <FaReddit className="icon" />, url: 'https://reddit.com' },
    { name: 'WhatsApp', icon: <FaWhatsapp className="icon" />, url: 'https://whatsapp.com' },
    { name: 'WeChat', icon: <FaWeixin className="icon" />, url: 'https://wechat.com' },
    { name: 'Telegram', icon: <FaTelegram className="icon" />, url: 'https://telegram.org' },
    { name: 'Skype', icon: <FaSkype className="icon" />, url: 'https://skype.com' },
    { name: 'Slack', icon: <FaSlack className="icon" />, url: 'https://slack.com' },
    { name: 'Zoom', icon: <FaVideo className="icon" />, url: 'https://zoom.us' },
    { name: 'YouTube', icon: <FaYoutube className="icon" />, url: 'https://youtube.com' },
    { name: 'Vimeo', icon: <FaVimeo className="icon" />, url: 'https://vimeo.com' },
    { name: 'Tumblr', icon: <FaTumblr className="icon" />, url: 'https://tumblr.com' },
    { name: 'Discord', icon: <FaDiscord className="icon" />, url: 'https://discord.com' },
    { name: 'Signal', icon: <FaSignal className="icon" />, url: 'https://signal.org' },
    { name: 'Viber', icon: <FaViber className="icon" />, url: 'https://viber.com' },
];

const defaultIcons = [
    'Gmail', 'Yahoo Mail', 'Outlook', 'AOL Mail', 'ProtonMail', 'Facebook', 'Twitter', 'Instagram', 'TikTok', 'LinkedIn'
];

const IconLinks = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedIcons, setSelectedIcons] = useState(() => {
        const savedIcons = localStorage.getItem('selectedIcons');
        return savedIcons ? JSON.parse(savedIcons) : defaultIcons;
    });

    useEffect(() => {
        localStorage.setItem('selectedIcons', JSON.stringify(selectedIcons));
    }, [selectedIcons]);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const toggleIcon = (name) => {
        setSelectedIcons((prevIcons) =>
            prevIcons.includes(name)
                ? prevIcons.filter((icon) => icon !== name)
                : [...prevIcons, name]
        );
    };

    const sortedIcons = [
        ...allIcons.filter(icon => selectedIcons.includes(icon.name)),
        ...allIcons.filter(icon => !selectedIcons.includes(icon.name))
    ];

    return (
        <>
            <div className="icon-links-container">
                <div className="icon-links">
                    {selectedIcons.map((name) => {
                        const icon = allIcons.find((icon) => icon.name === name);
                        return (
                            <a key={name} href={icon.url} target="_blank" rel="noopener noreferrer">
                                {icon.icon}
                            </a>
                        );
                    })}
                    <Button className="edit-button" onClick={handleShowModal}>
                        <FaPencilAlt className="pencil-icon" />
                    </Button>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Save Your Favorite Shortcuts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {sortedIcons.map((icon) => (
                        <div key={icon.name} onClick={() => toggleIcon(icon.name)} className="icon-item">
                            {icon.icon}
                            <span>{icon.name}</span>
                            <Button variant={selectedIcons.includes(icon.name) ? 'danger' : 'primary'} className="toggle-button">
                                {selectedIcons.includes(icon.name) ? '-' : '+'}
                            </Button>
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default IconLinks;