import './App.css';
import "./assets/css/Style.css";
import Main from './common/main';
import Routing from './routing/Routing';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Main>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
      />
      <Routing />
    </Main>
  );
}

export default App;
