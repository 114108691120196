import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const LinkTool = ({link, setLink, uploadLink}) => {
  return (
    <>
      <div className="common-height-footer">
        <Container>
          <div className="chat-inner">
            <div className="chat-header advanced-tool-outer">
              <Row className="justify-content-center">
                <Col xl={8} xxl={7}>
                  <div className="image-generation-area">
                    <div className="image-generation-head">
                      <img src={require("../assets/images/link.png")} alt="img" />
                      <div>
                        <h6>Link & Ask</h6>
                        <p>Paste a link & ask</p>
                      </div>
                    </div>
                    <div className="image-generation-body">
                      <h5>Link & Ask</h5>
                      <p>Search or ask about anything in a web page</p>
                      <p>
                        This tool allows you to enter a URL for a web page and ask
                        any question related to the content on that web page.
                      </p>
                      <Form onSubmit={(e) => {e.preventDefault(); uploadLink();}}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>Enter URL</Form.Label>
                          <div className="url-area">
                            <Form.Control type="text" placeholder="Enter URL" value={link} onChange={(e) => setLink(e.target.value.trim())} />
                            <Button
                              type="button"
                              variant="unset"
                              onClick={() => uploadLink()}
                            >
                              Submit
                            </Button>
                          </div>
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default LinkTool;
