import { Routes, Route } from "react-router-dom";
import Chat from "../pages/Chat";
import Paddle from "../pages/Paddle";
// import PaddleShortcuts from "../pages/PaddleShortcuts";
import AccountSetting from "../pages/AccountSetting";
import { useContext } from "react";
import { GlobalContext } from "../context/GlobalContext";
import RestPassword from "../pages/ResetPassword";
import PrivacyPolicy from "../common/PrivacyPolicy";
import TermsConditions from "../common/TermsConditions";
import RefundPolicy from "../common/RefundPolicy";
import NotFound from "../common/NotFound";
import { LandingPage } from "../pages/LandingPage";
import { LandingPageShort } from "../pages/LandingPageShort";
import { LandingPageShortcuts } from "../pages/LandingPageShortcuts";
import { SetPassword } from "../pages/SetPassword";
import { StripeCheckout } from "../pages/StripeCheckout";
import { SetPasswordStripe } from "../pages/SetPasswordStripe";
import {StripeCheckoutShort} from "../pages/StripeCheckoutShort";

const Routing = () => {

    const globalContext = useContext(GlobalContext);

    return (
        <Routes>
            <Route
                path="/checkout"
                element={<Paddle />}>
            </Route>
            <Route
                path="/stripe-checkout"
                element={<StripeCheckout />}>
            </Route>
            <Route
                path="/checkout-short"
                element={<StripeCheckoutShort />}>
            </Route>
            <Route
                path="/"
                element={<Chat />}>
            </Route>
            {globalContext.loggedIn && <>
                <Route
                    path="/account-setting"
                    element={<AccountSetting />}>
                </Route>
             
            </>}
            {!globalContext.loggedIn &&
                <>
                    <Route
                        path="/reset-password/:token"
                        element={<RestPassword />}>
                    </Route>
                    <Route
                        path="/get-help"
                        element={<LandingPage />}>
                    </Route>
                    <Route
                        path="/get-help/display/"
                        element={<LandingPage />}>
                    </Route>
                    <Route
                        path="/get-help/display"
                        element={<LandingPage />}>
                    </Route>
                    <Route
                        path="/account-short"
                        element={<LandingPageShort />}>
                    </Route>
                    <Route
                        path="/account-shortcuts"
                        element={<LandingPageShortcuts />}>
                    </Route>
                    <Route
                        path="/set-password/:transaction"
                        element={<SetPassword />}>
                    </Route>
                    <Route
                        path="/set-password/stripe"
                        element={<SetPasswordStripe />}>
                    </Route>
                </>
            }
   <Route
                    path="/privacy-policy"
                    element={<PrivacyPolicy />}>
                </Route>
                <Route
                    path="/terms-conditions"
                    element={<TermsConditions />}>
                </Route>
                <Route
                    path="/refund-policy"
                    element={<RefundPolicy/>}>
                </Route>
            
            
            <Route
                path="*"
                element={<NotFound />}>
            </Route>
        </Routes>
    );
};
export default Routing;