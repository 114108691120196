import axios from "axios";
import React, { useState } from "react";
import { apiUrl } from "../config/config";

export default function ImageFullScreen({image, setImage}) {

    const [downloading, setDownloading] = useState(false);

    const downloadImage = async () => {
        try {
            setDownloading(true);
            const response = await axios.get(`${apiUrl}download-image/`, {
                params: {
                    image_url: image
                },
                responseType: 'blob'
            });
            const image_name = image.split('/').pop();
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', image_name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            setDownloading(false);
        } catch (error) {
            console.error("Error downloading the image:", error);
            setDownloading(false);
        }
    };

    
    return (
        <div className="full-screen-overlay">
            <img src={image} alt="Full Screen" className="full-screen-image" />
            {downloading ? 
                <div className="download-loader"></div>
                :
                <button 
                onClick={downloadImage}
                className="download-icon"
                title="Download Image"
            >
            <img src={require("../assets/images/download-icon.png")} alt="img" />
            </button>}
            <span title="Close" className="close-icon" onClick={() => setImage(null)}>&times;</span>
        </div>
    )
}