import React, { useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import { apiService } from "../service/api.service";
import Swal from "sweetalert2";
import { truncateString } from "./common";
// import imageIcon from '../assets/images/image-icon.png';
import IconLinks from './IconLinks'; // Import the IconLinks component

const Sidebar = ({ open, setOpen }) => {

    const navigate = useNavigate();
    const [queryParams] = useSearchParams();
    const globalContext = useContext(GlobalContext);

    useEffect(() => {
        getThreads();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalContext.loggedIn])

    const getThreads = () => {
        if (!globalContext.loggedIn) {
            globalContext.setLoader(true);
            apiService.getGuestThread().then((res) => {
                if (res.status === 200) {
                    globalContext.setThreads(res.data.data);
                    globalContext.setLoader(false);
                }
            }).catch((err) => {
                console.error("err: ", err);
                globalContext.setLoader(false);
            })
        } else {
            globalContext.setLoader(true);
            apiService.getUserThread().then((res) => {
                if (res.status === 200) {
                    globalContext.setThreads(res.data.data);
                    globalContext.setLoader(false);
                }
            }).catch((err) => {
                console.error("err: ", err);
                globalContext.setLoader(false);
                if (err.response.status === 401) {
                    globalContext.setSessionComplete(true)
                }
            })
        }
    }

    const deleteThread = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                if (!globalContext.loggedIn) {
                    globalContext.setLoader(true);
                    apiService.deleteGuestThread(id).then((res) => {
                        if (res.status === 200) {
                            globalContext.setLoader(false);
                            getThreads();
                            if (queryParams.get("thread") === id) {
                                navigate(`/?chatType=${queryParams.get("chatType")}`);
                            }
                        }
                    }).catch((err) => {
                        console.log("err: ", err);
                        globalContext.setLoader(false);
                    })
                } else {
                    globalContext.setLoader(true);
                    apiService.deleteUserThread(id).then((res) => {
                        if (res.status === 200) {
                            globalContext.setLoader(false);
                            getThreads();
                            if (queryParams.get("thread") === id) {
                                navigate(`/?chatType=${queryParams.get("chatType")}`);
                            }
                        }
                    }).catch((err) => {
                        console.log("err: ", err);
                        globalContext.setLoader(false);
                        if (err.response.status === 401) {
                            globalContext.setSessionComplete(true)
                        }
                    })
                }
            }
        })
    }

    // Handle button clicks to switch chat types and reload the page
    const handleClick = (type) => {
        navigate(`/?chatType=${type}`);
        window.location.reload();
    };

    return (
        <>
            <div className="head-menu">
                <img src={require(`../assets/images/${open ? 'menu.png' : 'menu-close.png'}`)} alt="menu" onClick={() => setOpen(!open)} />
            </div>
            <section className={`sidebar${!open ? ' sidebar-hide': ''}`}>
                <div className="sidebar-head">
                   {!globalContext.generating ? <Link to="/?chatType=text"><h2>Helpr</h2></Link>: <h2>Helpr</h2>}
                </div>
                <div className="sidebar-body">
                    <Button disabled={globalContext.generating} type="button" variant="unset" onClick={() => handleClick('text')}>New Chat <img src={require("../assets/images/star.svg").default} alt="img" /></Button>
                    {/* <Button disabled={globalContext.generating} type="button" variant="unset" onClick={() => handleClick('image')}>Create Image <img src={imageIcon} alt="img" /></Button> */}

                    {/* Other components or buttons can go here */}

                    <div className="header-middle-below">
                        <IconLinks /> {/* Add the IconLinks component here */}
                    </div>

                    {globalContext.threads?.length > 0 ?
                        <div className="history-area">
                            <span>History</span>
                            <ul>
                                {globalContext.threads.map((thread, index) => (
                                    <li
                                    key={index}
                                    className={`${['image', 'text'].includes(thread.thread_type) ? 'aside-item': ""}${queryParams.get("thread") === thread.id ? " active" : ""}`}
                                    onClick={(event) => {
                                        // Prevent propagation of click event from child elements
                                        if (event.target.tagName !== 'I' && !globalContext.generating) {
                                            setOpen(true);
                                            navigate(`/?chatType=${thread.thread_type}&thread=${thread.id}`);
                                        }
                                    }}
                                >
                                    {thread.thread_type === "image" ? (
                                        <img src={require("../assets/images/history-image.png")} alt="img" />
                                    ) : thread.thread_type === "pdf" ? (
                                        <img src={require("../assets/images/history-pdf.png")} alt="img" />
                                    ) : thread.thread_type === "link" ? (
                                        <img src={require("../assets/images/history-link.png")} alt="img" />
                                    ) : null}
                                    {['image', 'text'].includes(thread.thread_type) ? thread.title : truncateString(thread.title, 22)}
                                    <i
                                        className="fa-solid fa-trash-can"
                                        onClick={(event) => {
                                            // Prevent bubbling up to parent li onClick
                                            if (!globalContext.generating) {
                                                event.stopPropagation();
                                                deleteThread(thread.id);
                                            }
                                        }}
                                    />
                                </li>
                                ))}
                            </ul>
                        </div>
                        :
                        <p>No Chat History</p>
                    }
                </div>
            </section>
        </>
    )
}

export default Sidebar;