import { useState } from "react";
import { Modal } from "react-bootstrap";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsConditions";
import RefundPolicy from "./RefundPolicy";
import ContactUs from "./ContactUs";
import { useLocation } from 'react-router-dom'; // Import useLocation

export const Footer = () => {
  const [show, setShow] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showRefund, setShowRefund] = useState(false);

  const location = useLocation(); // Get the current location
  const isRoot = location.pathname === "/get-help"; // Check if the path is "/"

  return (
    <>
      {isRoot && ( // Conditionally render the footer based on isRoot
        <div className="footer">
          <div className="footer-inner-right">
            <ul>
              <li>
                <span role="button" onClick={() => setShowPrivacy(true)}>
                  Privacy Policy
                </span>
              </li>
              <li>
                <span role="button" onClick={() => setShowTerms(true)}>
                  Terms & Conditions
                </span>
              </li>
              <li>
                <span role="button" onClick={() => setShowRefund(true)}>
                  Refund Policy
                </span>
              </li>
              <li>
                <span role="button" onClick={() => setShow(true)}>
                  Contact Us
                </span>
              </li>
            </ul>
          </div>
        </div>
      )}

      {/* Modals (outside the conditional rendering) */}
      <ContactUs show={show} setShow={setShow} />

      {/* Privacy Policy Modal */}
      <Modal
        className="popup-zindex"
        show={showPrivacy}
        onHide={() => setShowPrivacy(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PrivacyPolicy />
        </Modal.Body>
      </Modal>

      {/* Terms and Conditions Modal */}
      <Modal
        className="popup-zindex"
        show={showTerms}
        onHide={() => setShowTerms(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TermsConditions />
        </Modal.Body>
      </Modal>

      {/* Refund Policy Modal */}
      <Modal
        className="popup-zindex"
        show={showRefund}
        onHide={() => setShowRefund(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Refund Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RefundPolicy />
        </Modal.Body>
      </Modal>
    </>
  );
};
