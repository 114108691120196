import React from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NotFound = () => {

    const navigate = useNavigate();

    return (
        <>
            <Container>
                <div className="not-found-area">
                    <img src={require("../assets/images/error.png")} alt="img" />
                    <h2>Opps! Page Not Found</h2>
                    <p>Sorry, the page you're looking for doesn't exist. </p>
                    <Button onClick={() => navigate("/?chatType=text", { replace: true })} type="button" variant="unset">Back To Home</Button>
                </div>
            </Container>

        </>
    );
};
export default NotFound;