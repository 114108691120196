import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2";
import { GlobalContext } from "../context/GlobalContext";

export const SessionLogout = () => {

    let navigate = useNavigate();
    const globalContext = useContext(GlobalContext);

    // Whenever the sessionComplete globalstate will get true this component will render once
    useEffect(() => {
        Swal.fire("Your session has expired, Please login again.").then(() => {
            localStorage.removeItem("user-info");
            globalContext.setLoggedIn(false);
            globalContext.setUserInfo({});
            navigate('/?chatType=text')
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return true

}