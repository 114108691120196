import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="common-top-spacing">
                <section className="privacy-policy-outer">
                    <Container>
                        <div className="privacy-policy-inner">
                            <h1>Privacy Policy</h1>
                            
                            <h5>Introduction</h5>
                            <p>Helpr.Chat is owned and operated by Nomadic Creative LLC ("we," "our," or "us"). Nomadic Creative is dedicated to safeguarding your privacy. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our paid subscription service, Helpr.chat ("Service"). By accessing or using our Service, you agree to this Privacy Policy and our Terms of Service.</p>
                            
                            <h5>Definitions</h5>
                            <ul>
                                <li><b>Cookie:</b> A small file stored on your device to track and enhance your experience.</li>
                                <li><b>Personal Data:</b> Information that can identify an individual either directly or indirectly.</li>
                                <li><b>Service Providers:</b> External companies that assist in providing our services.</li>
                                <li><b>Usage Data:</b> Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself.</li>
                                <li><b>You:</b> The individual or entity using our Service.</li>
                            </ul>
                            
                            <h5>Information We Collect</h5>
                            <h6>Personal Data</h6>
                            <ul>
                                <li><b>Contact Information:</b> Name, email address, phone number.</li>
                                <li><b>Payment Information:</b> Credit card details or other payment information. Payment information is processed, collected, and stored by our online reseller & Merchant of Record, Paddle.com, who also handles order-related inquiries and returns.</li>
                                <li><b>Account Information:</b> Username, password, and usage preferences.</li>
                            </ul>
                            <h6>Usage Data</h6>
                            <ul>
                                <li><b>IP Address:</b> Internet Protocol address used to connect your device to the internet.</li>
                                <li><b>Device Information:</b> Type of device, operating system, and browser type.</li>
                                <li><b>Log Data:</b> Access times, pages viewed, and the referring URL.</li>
                            </ul>
                            <h6>Cookies and Tracking Technologies</h6>
                            <ul>
                                <li>We use cookies and similar technologies to track activity on our Service and store certain information. Cookies are used to:</li>
                                <ul>
                                    <li><b>Enhance User Experience:</b> Remember login details and preferences.</li>
                                    <li><b>Analyze Usage:</b> Monitor usage patterns to improve our Service.</li>
                                    <li><b>Marketing:</b> Provide tailored advertisements based on your interests.</li>
                                </ul>
                            </ul>
                            
                            <h5>How We Use Your Information</h5>
                            <h6>Providing and Improving Services</h6>
                            <ul>
                                <li><b>Provide Access:</b> Ensure you can use and benefit from our Service.</li>
                                <li><b>Enhance Features:</b> Improve functionality and user experience.</li>
                                <li><b>Customer Support:</b> Assist with inquiries and troubleshoot issues.</li>
                            </ul>
                            <h6>Marketing and Communications</h6>
                            <ul>
                                <li><b>Send Promotional Materials:</b> Inform you about new features, products, and services.</li>
                                <li><b>Customized Advertising:</b> Deliver personalized advertisements based on your preferences.</li>
                            </ul>
                            <h6>Compliance and Legal Obligations</h6>
                            <ul>
                                <li>We may use and disclose your information to comply with legal obligations, enforce our Terms of Service, and protect the rights and safety of our users and third parties.</li>
                            </ul>
                            
                            <h5>Sharing Your Information</h5>
                            <h6>Service Providers</h6>
                            <ul>
                                <li>We may share your information with third-party service providers that perform services on our behalf, such as payment processing, data analysis, email delivery, and customer service.</li>
                            </ul>
                            <h6>Business Transfers</h6>
                            <ul>
                                <li>In the event of a merger, sale, or acquisition, your information may be transferred as part of the business assets. You will be notified of any such change in ownership or control.</li>
                            </ul>
                            <h6>Legal Requirements</h6>
                            <ul>
                                <li>We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</li>
                            </ul>
                            
                            <h5>Data Security</h5>
                            <p>We implement robust security measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction. These measures include encryption, secure servers, and regular security assessments.</p>
                            
                            <h5>Sale of Business</h5>
                            <p>In the event of a sale, merger, or transfer of all or a significant portion of our assets, we may transfer your information to the new owner as part of the transaction. The new owner will be required to honor the commitments we have made in this Privacy Policy.</p>
                            
                            <h5>Data Retention</h5>
                            <p>We retain your personal data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy. Usage data may be retained for a shorter period, except when necessary for security, legal, or operational purposes.</p>
                            
                            <h5>Your Rights and Choices</h5>
                            <h6>Access and Update Information</h6>
                            <ul>
                                <li>You have the right to access and update your personal data. You can review and change your information by logging into your account.</li>
                            </ul>
                            <h6>Opt-Out of Marketing Communications</h6>
                            <ul>
                                <li>You can opt-out of receiving marketing emails from us by following the unsubscribe link in the emails or by contacting us directly.</li>
                            </ul>
                            <h6>Cookie Preferences</h6>
                            <ul>
                                <li>Most web browsers allow you to manage your cookie preferences. You can set your browser to refuse cookies or delete cookies. However, some features of our Service may not function properly without cookies.</li>
                            </ul>
                            
                            <h5>Children's Privacy</h5>
                            <p>Our Service is not intended for children under the age of 13. We do not knowingly collect personal data from children under 13. If we become aware that we have collected personal data from a child under 13, we will take steps to delete such information.</p>
                            
                            <h5>Changes to This Privacy Policy</h5>
                            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.</p>
                            
                            <h5>Contact Us</h5>
                            <p>If you have any questions about this Privacy Policy, please contact us:</p>
                            <ul>
                                <li><b>Email:</b> support@helpr.chat</li>
                                <li><b>Address:</b> Nomadic Creative LLC, 6318 Station Mill Drive, Norcross, GA 30092</li>
                            </ul>
                            <p><em>Last updated: July 29, 2024</em></p>
                        </div>
                    </Container>
                </section>
            </div>
        </>
    );
}
export default PrivacyPolicy;