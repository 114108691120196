import React from 'react';
import { ThreeDots } from 'react-loader-spinner'

export const Loader = () => {
    return (
        <div className='loader'>
            <ThreeDots className="loader-dev" color="#65A3FF" height={80} width={80} />
        </div>
    );
}
