import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const UploadPdf = ({ pdfFiles, setPdfFiles, uploadPdfFiles }) => {

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const pdfFiles = files.filter(file => file.type === 'application/pdf');
        setPdfFiles(pdfFiles);
    };

    return (
        <>
            <div className="common-height-footer">
                <Container>
                    <div className="chat-inner">
                        <div className="chat-header advanced-tool-outer">
                            <Row className="justify-content-center">
                                <Col xl={8} xxl={7}>
                                    <div className="image-generation-area">
                                        <div className="image-generation-head">
                                            <img src={require("../assets/images/pdf.png")} alt="img" />
                                            <div>
                                                <h6>Upload & Ask PDF
                                                </h6>
                                                <p>Chat with any PDF

                                                </p>
                                            </div>
                                        </div>
                                        <div className="image-generation-body">
                                            <h5>Upload & Ask PDF
                                            </h5>
                                            <p>Search or ask about anything in a document</p>
                                            <p>From legal agreements to financial reports, Chatbot App brings your documents to life. You can ask questions, get summaries, find information, and more. Simply upload the PDF documents you wish to interact with. Ask questions, extract data, and summarize documents using AI.</p>
                                            <div className="upload-pdf-area">
                                                <img src={require("../assets/images/upload.png")} alt="img" />
                                                <p>Drag and drop your document here</p>
                                                <Form.Control type="file" accept="application/pdf" size="lg" multiple onChange={handleFileChange} />
                                            </div>
                                            {pdfFiles.length > 0 &&
                                                <div>
                                                    <h6>Selected PDF Files:</h6>
                                                    <ul>
                                                        {pdfFiles?.map((file, index) => (
                                                            <li key={index}>{file.name}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            }
                                            <div className="uplaod-pdf-botom-btn">
                                                <Button onClick={uploadPdfFiles} disabled={pdfFiles?.length === 0}>Upload</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}
export default UploadPdf;