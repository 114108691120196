import React, { useContext, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { apiService } from "../service/api.service";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import Swal from "sweetalert2";

const RestPassword = () => {

    const params = useParams();
    const navigate = useNavigate();
    const globalContext = useContext(GlobalContext);
    const { register, handleSubmit, formState: { errors }, setError } = useForm();
    const [passType1, setPassType1] = useState("password");
    const [passType2, setPassType2] = useState("password");

    const resetPassword = (data) => {
        if (data.new_password !== data.confirm_password) {
            setError('confirm_password', { type: 'custom', message: "New password and confirm password doesn't match." });
            return;
        }
        globalContext.setLoader(true);
        apiService.resetPassword(data, params?.token).then((res) => {
            if (res.status === 200) {
                globalContext.setLoader(false);
                Swal.fire(res.data.detail).then(() => {
                    navigate("/?chatType=text&action=login", { replace: true });
                });
            }
        }).catch((err) => {
            console.log("err: ", err);
            globalContext.setLoader(false);
            setError('new_password', { type: 'custom', message: err.response.data.detail });
        })
    }

    return (
        <>
            <div className="common-top-spacing">
                <section className="account-setting-outer">
                    <Container>
                    <Row className="justify-content-center">
                        <Col md={10}  xl={6} xxl={5} className="col-12 mt-5">
                            <div className="login-content-inner">
                                <div className="login-content-head">
                                        <h2>Reset Password</h2>
                                        <p>Forgot your password? Let's get you back on track!</p>
                                </div>
                                <div className="login-content-body">
                                    <Form onSubmit={handleSubmit(resetPassword)}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>New Password</Form.Label>
                                            <div className="password-area">
                                            <div className="password-area-inner">
                                            <Form.Control type={passType1} placeholder="Enter Password" {...register("new_password", { required: "New Password is required.", pattern: {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
                                                message: "Password must contain at least 8 characters including uppercase, lowercase, digit, and special character."
                                            }})} onKeyDown={(e) => { if (e.key === " ") e.preventDefault() }}/>
                                            <i onClick={() => setPassType1(passType1 === "password" ? "text" : "password")} className={`fa-solid fa-eye${passType1 === "password" ? "-slash" : ""}`} />
                                            </div>
                                            {errors.new_password && <span className="err-msg">{errors.new_password.message}</span>}
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Confirm New Password</Form.Label>
                                            <div className="password-area">
                                                <div className="password-area-inner">
                                            <Form.Control type={passType2} placeholder="Enter Confirm New Password" {...register("confirm_password", { required: "Confirm New Password is required." })} onKeyDown={(e) => { if (e.key === " ") e.preventDefault() }} />
                                            <i onClick={() => setPassType2(passType2 === "password" ? "text" : "password")} className={`fa-solid fa-eye${passType2 === "password" ? "-slash" : ""}`} />
                                                </div>
                                            {errors.confirm_password && <span className="err-msg">{errors.confirm_password.message}</span>}
                                        </div>
                                        </Form.Group>
                  
                                        <Button variant="primary" type="submit">
                                            Reset
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    </Container>

                </section>
            </div>
        </>
    )
}
export default RestPassword