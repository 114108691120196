import React from "react";

const ImageGeneration = ({ questions, handleClick }) => {
    return (
        <>
            <div className="common-height-footer image-generation-height">
             
                    <div className="image-padding-override">
                        <div className="chat-header advanced-tool-outer">
                            <div className="justify-content-center">
                           
                                        {/* <div className="image-generation-head">
                                            <img src={require("../assets/images/generate-img.png")} alt="img" />
                                            <div>
                                                <h6>Image Generation</h6>
                                                <p>Create images using words</p>                                                                                                                                                 
                                            </div>
                                        </div> */}
                                        <div className="image-generation-body">
                                             


                                            <h1>
                                                        {/* Hello,   */}
                                                        {/* {globalContext.loggedIn ? globalContext.userInfo?.name ? globalContext.userInfo?.name : globalContext.userInfo?.email : "User"} */}
                                                        Hello!  I’m your Helpr Image Creator.
                                                    </h1>
                                                    <h2>Instantly turn any idea into stunning, professional looking images with just a few words!
                                                   </h2>


                                                   <div className="image-generation-headline">
                                           Examples:
                                            </div>


                                            <ul>
                                                {questions?.map((question, index) => (
                                                    <li key={index} onClick={() => handleClick(question)}>{question}</li>
                                                ))}
                                            </ul>
                                        </div>
                                   
                               
                            </div>
                        </div>
                    </div>
                
            </div>
        </>
    )
}
export default ImageGeneration;