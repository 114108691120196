import React, { useState, useEffect, useRef, useContext } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Form, Button } from 'react-bootstrap';
import { socketUrl } from '../config/config';
import { apiService } from '../service/api.service';
import ImageGeneration from './ImageGeneration';
import UploadPdf from './UploadPdf';
import LinkTool from './LinkTool';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContext';
import ImageFullScreen from '../common/ImageFullScreen';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Header from '../common/Header';


const Chat = () => {

    // const DUMMY_QUESTIONS = ["Generate a sales pitch for a new product.", "Help me to find a perfect laptop for my office setup.", "Generate a sales pitch for my new car."];
    const DUMMY_IMG_QUESTIONS = ["Create an image of a calico cat wearing a suit.", "Imagine a vibrant city street from the 1920s.", "Design a tranquil Japanese Zen garden setting."];
    // const [showLogin, setShowLogin] = useState(false); // State to control login popup visibility

    const location = useLocation();
    const navigate = useNavigate();
    const messagesEndRef = useRef(null);
    const textareaRef = useRef(null);
    const globalContext = useContext(GlobalContext);
    const [currentSocket, setCurrentSocket] = useState(null);
    const [question, setQuestion] = useState("");
    const [thread, setThread] = useState(null);
    const [messages, setMessages] = useState([]);
    const [pdfFiles, setPdfFiles] = useState([]);
    const [link, setLink] = useState("");
    const [queryParams] = useSearchParams();
    const [fullImageScreen, setFullImageScreen] = useState(null);
    const [shouldScrollBottom, setShouldScrollBottom] = useState(true);
    

    const { sendMessage, lastMessage, readyState } = useWebSocket(currentSocket);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    useEffect(() => {
        if (queryParams.get('chatType') === "text" || queryParams.get('chatType') === "image") {
            if (textareaRef.current) {
                textareaRef.current.focus();
            }
        }
        if (queryParams.get('thread')) {
            getThreadMessages();
            setThread(queryParams.get('thread'));
        } else {
            setThread(null);
            setMessages([]);
            if (!globalContext.loggedIn) {
                getMachineIp();
            } else {
                setCurrentSocket(`${socketUrl}chat/${globalContext.userInfo.id}/`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location.search, queryParams.get('chatType')]);


  

    useEffect(() => {
        if (queryParams.get('chatType') === "text" || queryParams.get('chatType') === "image") {
            if (textareaRef.current) {
                textareaRef.current.focus();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams.get('chatType')]);






    function formatContent(content) {
        content = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
        content = content.replace(/^(#{1,6})\s*(.*?)$/gm, function (match, p1, p2) {
            const level = p1.length;
            return `<h${level}>${p2}</h${level}>`;
        });
        content = content.replace(/^-\s*(.*)$/gm, '<ul><li>$1</li></ul>');
        content = content.replace(/\n/g, '<br/>');
        return content;
    }

    useEffect(() => {
        if (shouldScrollBottom && messagesEndRef.current && messages.length > 0) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, shouldScrollBottom]);

    const handleScroll = () => {
        const isScrolledToBottom = messagesEndRef.current.getBoundingClientRect().bottom <= window.innerHeight;
        setShouldScrollBottom(isScrolledToBottom);
    };




    // useEffect(() => {
    //     if (!globalContext.loggedIn) {
    //         alert("Please login to continue.");
    //         globalContext.setShowLogin(true); // Trigger the login modal from the global context
    //     }
    // }, [globalContext.loggedIn, globalContext]); 



    

    useEffect(() => {
        if (lastMessage !== null) {
            handleScroll();
            const data = JSON.parse(lastMessage.data);
            console.log("last message data: ", data);
            setMessages(prevMessages => {
                const newMessages = [...prevMessages];
                if (data.message) {
                    setThread(data.thread);
                    newMessages[newMessages.length - 1].response = formatContent(data.message);
                    return newMessages;
                } else if (data.error) {
                    if (data.error.includes("Limit reached")) {
                        globalContext.setLock(true);
                        newMessages[newMessages.length - 1].response = "";
                        return newMessages;
                    } else {
                        newMessages[newMessages.length - 1].response = data.error;
                        return newMessages;
                    }
                } else {
                    return prevMessages;
                }
            });
            globalContext.setGenerating(data.stream ? true : false);
            if (data?.thread && data?.thread !== queryParams.get('thread') && !data?.stream) {
                navigate(`/?chatType=${queryParams.get('chatType')}&thread=${data.thread}`, { replace: true });
            }
            globalContext.setThreads(prevThreads => {
                if (data.thread_title) {
                    const existingThreadIndex = prevThreads?.findIndex(thread => thread.id === data.thread);
                    if (existingThreadIndex !== -1) {
                        const updatedThreads = [...prevThreads];
                        updatedThreads[existingThreadIndex].title = data.thread_title;
                        return updatedThreads;
                    } else {
                        return [{ id: data.thread, title: data.thread_title, thread_type: queryParams.get('chatType') }, ...prevThreads];
                    }
                }
                return prevThreads;
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessage]);

    useEffect(() => {
        if (!queryParams.get('chatType') || !['text', 'image', 'pdf', 'link'].includes(queryParams.get('chatType'))) {
            navigate("/?chatType=text");
        }
        setPdfFiles([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    useEffect(() => {
        setMessages([]);
        globalContext.setLock(false);
        globalContext.setGenerating(false);
        if (!globalContext.loggedIn) {
            getMachineIp();
        } else {
            setCurrentSocket(`${socketUrl}chat/${globalContext.userInfo.id}/`)
            if (globalContext.showPlanScreen && !globalContext.userInfo?.stripe_subscription_id) {
                globalContext.setPlanModal(true);
            }
            globalContext.setShowPlanScreen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalContext.loggedIn]);

    const getMachineIp = () => {
        apiService.getMyIp().then((res) => {
            if (res.status === 200) {
                setCurrentSocket(`${socketUrl}guest/chat/${res.data.ip}/`)
            }
        })
    }

    const handleSuggestionClick = (item) => {
        setQuestion(item);
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    };

    const connectUserSocket = () => {
        const chatType = queryParams.get('chatType');
        const thread = queryParams.get('thread');
        if (globalContext.loggedIn) {
            if (chatType === 'text') {
                setCurrentSocket(`${socketUrl}chat/${globalContext.userInfo.id}/`)
            } else if (chatType === 'pdf') {
                setCurrentSocket(`${socketUrl}pdf/${thread}/`)
            } else if (chatType === 'link') {
                setCurrentSocket(`${socketUrl}link/${thread}/`)
            } else {
                setCurrentSocket(null);
            }
        } else {
            getMachineIp();
        }
    }

    const getThreadMessages = () => {
        if (globalContext.loggedIn) {
            apiService.getUserThreadMessage(queryParams.get('thread')).then((res) => {
                if (res.status === 200) {
                    const formattedMessages = res.data.data.map(message => ({
                        ...message,
                        question: formatContent(message.question),
                        response: queryParams.get('chatType') === 'image' ? message.generated_image : formatContent(message.response)
                    }));
                    if (queryParams.get('thread')) {
                        setMessages(formattedMessages);
                    }
                    connectUserSocket();
                    handleSuggestionClick("");
                    globalContext.setLoader(false);
                }
            }).catch((err) => {
                console.log("err: ", err);
                globalContext.setLoader(false);
                if (err.response.status === 401) {
                    globalContext.setSessionComplete(true)
                }
                navigate("/?chatType=text", { replace: true });
            })
        } else {
            apiService.getGuestThreadMessage(queryParams.get('thread')).then((res) => {
                if (res.status === 200) {
                    const formattedMessages = res.data.data.map(message => ({
                        ...message,
                        response: formatContent(message.response)
                    }));
                    if (queryParams.get('thread')) {
                        setMessages(formattedMessages);
                    }
                    handleSuggestionClick("");
                    globalContext.setLoader(false);
                }
            }).catch((err) => {
                console.log("err: ", err);
                globalContext.setLoader(false);
                navigate("/?chatType=text", { replace: true });
            })
        }
    }

    const renderMessages = () => {
        if (messages.length > 0) {
            return (
                <div className="scrollable-container">
                    {messages?.map((msg, index) => (
                        <div key={index}>
                            {msg.question && (
                                <div key={`${index}_ques`} className="user-chat-outer question-area">
                                    <img src={globalContext?.userInfo?.profile_image ? globalContext.userInfo?.profile_image : require("../assets/images/profile.png")} alt="img" />
                                    <div className="user-chat-right">
                                        <h6>{globalContext.loggedIn ? globalContext.userInfo?.name ? globalContext.userInfo?.name : globalContext.userInfo?.email : 'User'}</h6>
                                        <p dangerouslySetInnerHTML={{ __html: msg.question }} />
                                    </div>
                                </div>
                            )}
                            {msg.response && (
                                <div key={`${index}_res`} className={`user-chat-outer ${msg.sender === 'helpr' ? 'helpr-response' : ''}`}>
                                    <img src={require("../assets/images/helpr-profile.png")} alt="img" />
                                    <div className="user-chat-right">
                                        <h6>Helpr</h6>
                                        {queryParams.get('chatType') === 'image' ? (
                                            <img className='generated-image' title="Click to view full image" src={msg.response} alt="img" onClick={() => setFullImageScreen(msg.response)} />
                                        ) : (
                                            <p dangerouslySetInnerHTML={{ __html: msg.response }} />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                </div>
            );
        }
    };

    const generateImage = () => {
        globalContext.setLoader(true);
        globalContext.setGenerating(true);
        const data = { prompt: question };
        if (thread) {
            data.thread = thread;
        }
        apiService.generateImage(data).then((res) => {
            if (res.status === 201) {
                globalContext.setLoader(false);
                setMessages([...messages, { "question": formatContent(question), "response": res.data.data.generated_image }]);
                setThread(res.data.data.thread);
                globalContext.setGenerating(false);
                if (res.data.data.thread && res.data.data.thread !== queryParams.get('thread')) {
                    navigate(`/?chatType=${queryParams.get('chatType')}&thread=${res.data.data.thread}`, { replace: true });
                    globalContext.setThreads(prevThreads => {
                        const existingThreadIndex = prevThreads?.findIndex(thread => thread.id === res.data.data.thread);
                        if (existingThreadIndex !== -1) {
                            return prevThreads;
                        } else {
                            return [{ id: res.data.data.thread, title: question, thread_type: queryParams.get('chatType') }, ...prevThreads];
                        }
                    })
                }
                handleSuggestionClick("");
            }
        }).catch((err) => {
            console.log("err: ", err);
            globalContext.setGenerating(false);
            globalContext.setLoader(false);
            if (err.response.status === 401) {
                globalContext.setSessionComplete(true)
            }
        })
    }

    const isValidHttpUrl = (str) => {
        const pattern = new RegExp(
            '^(https?:\\/\\/)?' +
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
            '(\\?[;&a-z\\d%_.~+=-]*)?' +
            '(\\#[-a-z\\d_]*)?$',
            'i'
        );
        return pattern.test(str);
    }

    const uploadLink = (link) => {
        toast.dismiss();
        if (!isValidHttpUrl(link)) {
            toast.error("Please enter a valid link.");
            return;
        }
        globalContext.setLoader(true);
        const data = { "link": link }
        apiService.uploadLink(data).then((res) => {
            if (res.status === 200) {
                setLink("");
                setMessages([]);
                setThread(res.data.data.id);
                setQuestion("");
                globalContext.setLoader(false);
                navigate(`/?chatType=link&thread=${res.data.data.id}`, { replace: true });
                globalContext.setThreads(prevThreads => {
                    return [{ id: res.data.data.id, title: res.data.data.title, thread_type: queryParams.get('chatType') }, ...prevThreads];
                })
            }
        }).catch((err) => {
            console.log("err: ", err);
            globalContext.setLoader(false);
            Swal.fire("Error", err.response.data.detail, "error");
            setLink("");
            if (err.response.status === 401) {
                globalContext.setSessionComplete(true)
            }
        })
    }


    

    const sendQuestion = () => {
        if (!globalContext.loggedIn) {
            // alert("Please logins to continue.");
            globalContext.setShowLogin(true); // Trigger the login modal from the global context
            return;
        }
        console.log("connectionStatus: ", connectionStatus);
        if (!globalContext.generating && question.trim()) {
            if (isValidHttpUrl(question.trim())) {
                uploadLink(question.trim());
                return;
            }

            if (queryParams.get("chatType") === "text" || queryParams.get("chatType") === "pdf" || queryParams.get("chatType") === "link") {
                if (connectionStatus === "Open") {
                    globalContext.setGenerating(true);
                    const data = { "message": question };
                    if (thread) {
                        data.thread = thread;
                    }
                    sendMessage(JSON.stringify(data));
                    setMessages([...messages, { "question": formatContent(question), "response": "Typing..." }]);
                    setQuestion("");
                }
            } else if (queryParams.get("chatType") === "image") {
                handleScroll();
                generateImage()
            }
        }
    }

    const subscribe = () => {
        if (!globalContext.loggedIn) {
            globalContext.setLock(false);
            // navigate(`/checkout?trial=${globalContext.planList[0].id}`, { replace: true });
            // INTEGRATED FOR STRIPE
            navigate(`/stripe-checkout`, { replace: true });
        } else {
            globalContext.setLock(false);
            globalContext.setPlanModal(true);
        }
    }

    const uploadPdfFiles = () => {
        globalContext.setLoader(true);
        const formData = new FormData();
        for (let i = 0; i < pdfFiles.length; i++) {
            formData.append("files", pdfFiles[i]);
        }
        apiService.uploadPdfFiles(formData).then((res) => {
            if (res.status === 200) {
                setPdfFiles([]);
                setMessages([]);
                setThread(res.data.data.id);
                setQuestion("");
                globalContext.setLoader(false);
                navigate(`/?chatType=pdf&thread=${res.data.data.id}`, { replace: true });
                globalContext.setThreads(prevThreads => {
                    return [{ id: res.data.data.id, title: res.data.data.title, thread_type: queryParams.get('chatType') }, ...prevThreads];
                })
            }
        }).catch((err) => {
            console.log("err: ", err);
            globalContext.setLoader(false);
            Swal.fire("Error", err.response.data.detail, "error");
            setPdfFiles([]);
            if (err.response.status === 401) {
                globalContext.setSessionComplete(true)
            }
        })
    }

    function checkSubscription(e) {
        e.preventDefault();
        navigate("/account-setting?active=profile")
    }

    window.checkSubscription = checkSubscription;

    return (
        <>
            <div className="common-top-spacing">
                <section className="chat-outer">
                    {fullImageScreen && <ImageFullScreen image={fullImageScreen} setImage={setFullImageScreen} />}
                    <div className="chat-inner">
                        <div className="chat-header">
                            {messages?.length === 0 &&
                                <>
                                    {location.pathname === '/' && queryParams.get('chatType') === 'text' ?
                                        <>
                                            <h1>Hello! I’m your Helpr Chat Assistant.</h1>
                                            <h2>You can ask me anything about any topic. How can I help you today?</h2>
                                            {/* <div className="image-generation-headline">
                                                Examples:
                                            </div> */}
                                            <ul className='example-questions'>
                                                <li>How do I speed up my computer?</li>
                                                <li>Give me a summary of recipiemaster.com</li>
                                                <li>How do I bake an apple pie from scratch?</li>
                                            </ul>
                                        </>
                                        : location.pathname === '/' && queryParams.get('chatType') === 'image' ?
                                            <ImageGeneration questions={DUMMY_IMG_QUESTIONS} handleClick={handleSuggestionClick} /> :
                                            location.pathname === '/' && queryParams.get('chatType') === 'pdf' && !queryParams.get('thread') ?
                                                <UploadPdf pdfFiles={pdfFiles} setPdfFiles={setPdfFiles} uploadPdfFiles={uploadPdfFiles} /> :
                                                location.pathname === '/' && queryParams.get('chatType') === 'link' && !queryParams.get('thread') ?
                                                    <LinkTool link={link} setLink={setLink} uploadLink={() => uploadLink(link)} /> :
                                                    location.pathname === '/' && queryParams.get('chatType') === 'pdf' && queryParams.get('thread') ?
                                                        <p className='pdf-complete'>The PDF upload is complete. Feel free to ask your questions now.</p> :
                                                        location.pathname === '/' && queryParams.get('chatType') === 'link' && queryParams.get('thread') ?
                                                            <p className='pdf-complete'>The link has been added successfully. Feel free to ask your questions now.</p> : <></>}
                                </>
                            }
                            {globalContext.lock &&
                                <div className="blur-overlay">
                                    <img src={require("../assets/images/lock.png")} alt="img" />
                                    <p>Unlock advanced features of Helpr with a subscription plan.</p>
                                    
                                    <Button type="button" variant="unset" onClick={() => subscribe()}>
                                        Subscribe
                                    </Button>
                                </div>
                            }
                            {renderMessages()}
                            <div ref={messagesEndRef} />
                        </div>
                        <div className="chat-footer">
    {(queryParams.get("chatType") === "text" || queryParams.get("chatType") === "image" || queryParams.get("thread")) && (
        <>
            <div className="footer-inner text-area">
                <Form.Control 
                    ref={textareaRef} 
                    as="textarea" 
                    rows={3} 
                    placeholder={queryParams.get("chatType") === "image" 
                        ? "Describe the image you want to generate..." 
                        : "Type your question here..."
                    }
                    value={question} 
                    onChange={(e) => setQuestion(e.target.value)} 
                    disabled={globalContext.lock || globalContext.disableGeneration} 
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            sendQuestion();
                        }
                    }} 
                />
                <button 
                    className="send-btn" 
                    onClick={() => sendQuestion()} 
                    disabled={globalContext.generating || globalContext.lock || !question}
                >
                    <img src={require("../assets/images/send-icon.png")} alt="send" />
                </button>
            </div>
            <p>Helpr may display inaccurate info, including about people, so double-check its responses.</p>
        </>
    )}
</div>
                    </div>
                </section>
            </div>
            {globalContext.showLogin && (
                <Header />
            )}
        </>
    );
}

export default Chat;